<template>
  <div class="dentist-details-area" style="padding-bottom: 50px">


    <div style="display: flex; justify-content: center">
      <div style="width: 55rem">
        <div class="img-position">
          <div class="image-container">
            <img :src="doctor.headimg" alt="avatar">
          </div>
        </div>
        <div>
          <div class="center-position" style="margin-top: 10px">
            <span class="xingming">{{ doctor.ysxm }}</span>
            <!--            <span class="zhicheng">{{doctor.zhicheng}}</span>-->
          </div>
          <div class="center-position">
            <!--            <span class="zhicheng">首都医科大学附属北京朝阳医院</span>-->
            <!--            <span class="zhicheng">{{doctor.ksmc}}</span>-->
            <span class="zhicheng">{{ doctor.ksmc }} {{ doctor.zhicheng }}</span>
          </div>
        </div>
        <div style="margin-top: 10px" class="container">
          <div class="title-block">
            <span class="rectangle"></span>
            <span style="margin-left: 5px; font-size: 1.1rem">出诊时间</span>
          </div>
          <div style="padding:5px 15px 0px 25px" class="container">
            <div class="row">
              <div v-for="(czb,index) in scheduling" :key="index" style="padding-top: 10px; padding-bottom: 5px">

                <div class="d-flex justify-content-between align-items-center">
                  <div style="font-size: 1rem;" class="col-8">
                    <div>
                      <span style="font-size: 1rem">{{ czb.jzrq }}</span>
                      <span class="gh-detail">周{{ czb.week }}</span>
                      <span class="gh-detail">{{ czb.sxw }}</span>
                    </div>
                    <div>
                      <span>剩余挂号数量：{{ czb.sy }}</span>
                    </div>
                    <div>
                      <div>
                        <span class="ghf-class">挂号费：</span>
                        <span class="yuan-class">{{ czb.ghf }}元</span>
                      </div>
                    </div>
                    <div v-if="czb.payerTotal">
                      <span class="ghf-class">预约费：</span>
                      <span class="yuan-class">{{ czb.yyf }}元</span>
                    </div>
                  </div>
                  <button v-if="!czb.isLstz" type="button" :disabled="!czb.hassy"
                          :style="{backgroundColor: czb.hassy ? '#804e22' : 'gray'}" class="custom-button"
                          @click="toAppointmentDoctor(czb._id)">预约
                  </button>
                  <button v-else type="button" disabled style="background-color: #b00303" class="custom-button">停诊
                  </button>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div style="margin-top: 10px" class="container">
          <div class="title-block">
            <span class="rectangle"></span>
            <span style="margin-left: 5px;font-size: 1.1rem">医生擅长</span>
          </div>
          <div style="padding:5px 15px 0px 25px" class="container">
            <div style="font-size: 1rem" class="alignText">
              <p>
                {{ doctor.zytc }}
                <!--                妇科、心脑血管病、肝病、肾病、胃肠疾病、神经衰弱、精神抑郁、糖尿病、高血压、不孕不育症等都有独特的疗效。中医治疗疑难病、常见病等各种疑难杂症。-->
              </p>
            </div>
          </div>

        </div>
        <div style="margin-top: 10px" class="container">
          <div class="title-block">
            <span class="rectangle"></span>
            <span style="margin-left: 5px;font-size: 1.1rem">医生简介</span>
          </div>
          <div style="padding:5px 15px 0px 25px" class="container">
            <div style="font-size: 1rem" class="alignText">
              <p>
                {{ doctor.ysjj }}
                <!--                出生中医名家，毕业于解放军第一军医大学。从事中医40余年，具有扎实的理论基础和丰富的临床经验，精通中医诊法与辨证，获山东省德州名中医专家称号。诊法独特，疗效确切。-->
              </p>
            </div>
          </div>

        </div>

        <div style="margin-top: 10px" class="container">
          <div class="title-block">
            <span class="rectangle"></span>
            <span style="margin-left: 5px;font-size: 1.1rem">医生二维码</span>
          </div>
          <div style="padding:5px 15px 0px 25px" class="container">
            <div style="font-size: 1rem" class="alignText">
              <img class="" style="width: 8rem;" :src="'https://api.zhycit.com/rhzxyy/wx/qrCode?s=jszyy&env_version=release&path=/pages/model/doctorInfo?index='+index" mode="aspectFill" lazy-load="false" binderror="" bindload="">
                <!-- env_version:正式版为 "release"，体验版为 "trial"，开发版为 "develop" -->
              </img>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {useRoute} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {getDoctorInfo} from "@/api/doctor/doctorApi";
import {useDoctorStore} from "@/stores/useDoctorStore.ts";
import router from "@/router";
import Api from "@/request/api";
import {useAppointmentStore} from "@/stores/useAppointmentStore.ts";

defineProps({
  name: {
    type: String,
    default: 'DoctorDetails'
  }
});

const route = useRoute();
const index = parseInt(route.query.index, 10);
const openid = route.query.openid;

const scheduling = ref([])
const page = ref(1);
const doctor = ref({})

const emit = defineEmits(['sendPage']);

const sendPage = () => {
  emit('sendPage', (Math.floor(index / 9) + 1))
}

onMounted(() => {

  console.log(index)
  Api.getSchedulingList(index)
      .then((res) => {
        console.log("=======")
        console.log(res)
        scheduling.value = res.data
      })
  Api.getDoctorById(index)
      .then((res) => {
        console.log(res)
        doctor.value = res.data
      })

})


const toAppointmentDoctor = (id) => {
  // useAppointmentStore().setYsId(ysId);
  // useAppointmentStore().setCzbIndex(czb_index);
  useAppointmentStore().setScId(id);
  if (!route.path.startsWith('/app')) {
    router.push({path: '/yuyue-doctor'})
  } else {
    router.push({path: '/app/yuyue-doctor', query: {type: 'xcx', openid: openid}})
  }
}

const format = (str) => {
  return str.replace(/\/n/g, '<br/>')
}
</script>

<style scoped>
/*================================================
小程序两端文字对齐 CSS
=================================================*/
.alignText {
  text-align: justify;
}

.alignText::after {
  width: 100%;
  display: inline-block;
  /* content: '';*/
}

>>> p::before {
  content: ''; /* 创建一个伪元素 */
  display: inline-block; /* 确保其行为像块元素 */
  width: 2em; /* 设置空格宽度 */
}

.image-container {
  width: 80px; /* 固定宽度 */
  height: 80px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}

.img-position {
  justify-content: center;
  display: flex;
}


.center-position {
  justify-content: center;
  display: flex;
  align-items: flex-end;
}

.xingming {
  font-size: 1.3rem;
  font-weight: bold;
}

.zhicheng {
  padding-left: 10px;
  font-size: 1rem;
  padding-bottom: 1px;
}

.rectangle {
  display: inline-block;
  width: 3px; /* 长方形的宽度，可以根据需要调整 */
  height: 23px; /* 长方形的高度为 19px */
  background-color: #804e22; /* 背景颜色 */
}

.title-block {
  display: flex;
  align-items: flex-end;
  height: 25px;
  padding: 0 15px;
}

.custom-button {
  font-size: 15px;
  padding: 5px 15px;
  border-radius: 20px;
  border: none;
  /*  background-color: #804e22;*/
  color: #fff;
  text-align: center;
}

.gh-detail {
  padding-left: 5px;
  font-size: 1rem;
}

.ghf-class {
  font-weight: normal;
  color: #999999;
  font-size: 1rem;
}

.yuan-class {
  font-size: 1rem;
  color: #de0404
}
</style>